<template>
  <div class="table-main dash-table dash-orders">
    <h2>Pedidos recentes</h2>
    <div class="table-wrap" v-if="orders.length > 0">
      <table>
        <tbody>
          <tr v-for="order in orders" v-bind:key="order.id">
            <td class="orders-number">
              <div class="status-wrap" :class="order.attributes.status">
                <div class="status">
                  <template v-if="order.attributes.status == 'requested'">
                    Solicitado
                  </template>
                  <template v-if="order.attributes.status == 'sent'">
                    Aceito
                  </template>
                  <template v-if="order.attributes.status == 'approved'">
                    Aprovado
                  </template>
                  <template v-if="order.attributes.status == 'failed'">
                    Enviando
                  </template>
                  <template v-if="order.attributes.status == 'cancelled'">
                    Cancelado
                  </template>
                  <template v-if="order.attributes.status == 'rejected'">
                    Rejeitado
                  </template>
                  <template v-if="order.attributes.status == 'blocked'">
                    Bloqueado
                  </template>
                  <template v-if="order.attributes.status == 'inactive'">
                    Desativado
                  </template>
                  <template v-if="order.attributes.status == 'completed'">
                    Concluído
                  </template>
                  <template v-if="order.attributes.status == 'finished'">
                    Concluído
                  </template>
                  <template v-if="order.attributes.status == 'onroute'">
                    A caminho
                  </template>
                  <template v-if="order.attributes.status == 'draft'">
                    Rascunho
                  </template>
                </div>
              </div>
              <a v-if="order.attributes.description" class="main-link" @click="showOrderDetails(order); addBodyClass()">
                {{ order.attributes.description }}
              </a>
              <a v-else class="main-link" @click="showOrderDetails(order); addBodyClass()">
                Pedido sem descrição
              </a>
            </td>
            <td class="date-time">
              <p>{{ $formattedDateWithoutHours(order.attributes.createdAt) }}</p>
              <p>{{ $formattedDateOnlyHours(order.attributes.createdAt) }}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="buttons-wrap">
          <button class="main-btn create-order" @click="showNewOrderModal = true;  addBodyClass()" v-if="user && user.relationships && user.relationships.price && user.relationships.price.attributes.name !== null">
              <p>Criar novo</p>
              <div></div>
          </button>
          <button @click="this.$router.push('Orders') " class="main-btn grey-btn see-orders">Ver tudo</button>
      </div>
      <ModalOrderDetail v-if="showOrderDetailsModal" :order="selectedOrder" @close="selectedOrder = null" />
      <ModalOrderCreate :show="showNewOrderModal" @close="showNewOrderModal = false" @submit="createOrder" />
    </div>
    <div class="table-wrap empty" v-else>
      <p>Você ainda não realizou pedidos</p>
      <div class="buttons-wrap">
          <button class="main-btn create-order" @click="showNewOrderModal = true; addBodyClass()">
              <p>Realizar o primeiro pedido</p>
              <div></div>
          </button>
      </div>
      <ModalOrderCreate :show="showNewOrderModal" @close="showNewOrderModal = false" @submit="createOrder" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModalOrderCreate from '@/components/client/ModalOrderCreate.vue'
import ModalOrderDetail from '@/components/client/ModalOrderDetail.vue'

export default {
  // props: ['orders'],
  data () {
    return {
      selectedOrder: null,
      showNewOrderModal: false,
      showOrderDetailsModal: false
    }
  },
  computed: {
    ...mapGetters(['orders', 'user'])
  },
  methods: {
    ...mapActions(['fetchOrders']),
    showOrderDetails (order) {
      console.log('Botão "Realizar o primeiro" clicado')
      this.selectedOrder = null
      this.$nextTick(() => {
        this.selectedOrder = Object.assign({}, order)
        this.showOrderDetailsModal = true
      })
      this.showOrderDetailsModal = true
    },
    addBodyClass () {
      document.body.classList.add('modal-opened')
    }
  },
  created () {
    this.fetchOrders({ sort: '-created-at' })
  },
  components: { ModalOrderCreate, ModalOrderDetail }
}
</script>
