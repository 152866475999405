<template>
    <div>
        <div class="user-warning" v-if="warningMessage">
            <strong>{{ warningMessage }}</strong>
        </div>
        <div class="user-header">
            <div v-if="user.attributes" class="user-wrap">
                <div class="user" v-if="!isLoading">
                    <p>Olá, {{user.attributes.firstName}}</p>
                    <span></span>
                </div>
                <a href="#" class="logout" @click="logout">
                    <div></div>
                    <p>Sair</p>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['user', 'isLoggedIn', 'isLoading']),
    // shouldShowWarning () {
    //   if (!this.user || !this.user.relationships) {
    //     return false
    //   }
    //   if (!this.user.relationships.price || !this.user.relationships.price.attributes) {
    //     return false
    //   }
    //   if (!this.user.relationships.price.attributes.endAt) {
    //     return false
    //   }
    //   const currentDate = new Date().toISOString().split('T')[0]
    //   const endAtDate = this.user.relationships.price.attributes.endAt
    //   const diffDays = this.calculateDateDifference(currentDate, endAtDate)
    //   return diffDays < 30
    // }
    warningMessage () {
      if (!this.user || !this.user.relationships || !this.user.relationships.price || !this.user.relationships.price.attributes || !this.user.relationships.price.attributes.endAt) {
        return null
      }

      const currentDate = new Date().toISOString().split('T')[0]
      const endAtDate = this.user.relationships.price.attributes.endAt
      const diffDays = this.calculateDateDifference(currentDate, endAtDate)

      if (diffDays < 0) {
        this.$store.dispatch('updatePriceTableExpired', true)
        return 'A sua tabela de preços expirou. Entre em contato para atualizar seus preços e voltar a realizar pedidos e uploads de notas.'
      } else {
        this.$store.dispatch('updatePriceTableExpired', false)
      }

      if (diffDays < 30) {
        return `A sua tabela de preços atual irá expirar em ${this.formatDate(endAtDate)}. Submeta seus pedidos em rascunho para garantir a aquisição com os preços atuais.`
      }

      return null
    }
  },
  methods: {
    ...mapActions(['logout']),
    calculateDateDifference (date1, date2) {
      const dt1 = new Date(date1)
      const dt2 = new Date(date2)
      const diffTime = Math.abs(dt2 - dt1)
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    },
    formatDate (date) {
      if (!date) return ''
      const formattedDate = new Date(date)
      return formattedDate.toLocaleDateString('pt-BR')
    }
  }
}
</script>
