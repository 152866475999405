import { createStore } from 'vuex'
import axios from '../config/axiosConfig.js'
import router from '../router'
import { nextTick, reactive } from 'vue'

const baseUrl = `${process.env.VUE_APP_API_URL}/api`

const AUTH_REQUEST = 'AUTH_REQUEST'
const AUTH_SUCCESS = 'AUTH_SUCCESS'
const AUTH_ERROR = 'AUTH_ERROR'
const LOGOUT = 'LOGOUT'
const SET_USER = 'SET_USER'
const SET_ORDERS = 'SET_ORDERS'
const SET_CODES = 'SET_CODES'
const SET_ADMINCODES = 'SET_ADMINCODES'
const SET_STATEMENTS = 'SET_STATEMENTS'
const SET_HUNTS = 'SET_HUNTS'
const SET_ITEMS = 'SET_ITEMS'
const SET_NFE_ITEMS = 'SET_NFE_ITEMS'
const SET_USERS = 'SET_USERS'
const CLEAR_STATE = 'CLEAR_STATE'
const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES'
const SET_LOADING_STATE = 'SET_LOADING_STATE'
const SET_PROMOS = 'SET_PROMOS'
const SET_COMPENSATIONS = 'SET_COMPENSATIONS'
const SET_ACTIVITIES = 'SET_ACTIVITIES'
const SET_ADMINACTIVITIES = 'SET_ADMINACTIVITIES'
const SET_MODAL_UPLOAD_INVOICE_OPENED = 'SET_MODAL_UPLOAD_INVOICE_OPENED'
const SET_MODAL_SET_ITEM_RELATIONSHIP_OPENED = 'SET_MODAL_SET_ITEM_RELATIONSHIP_OPENED'

export default createStore({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
    users: [],
    orders: [],
    codes: [],
    admincodes: [],
    statements: [],
    promos: [],
    activities: [],
    adminactivities: [],
    compensations: [],
    hunts: [],
    nfe_items: [],
    user_access: localStorage.getItem('user_access') || '',
    total_pages: 0,
    modal_set_item_relationship_opened: false,
    modal_upload_invoice_opened: false,
    isLoading: false,
    isPriceTableExpired: false
  },
  mutations: {
    [AUTH_REQUEST] (state) {
      state.status = 'loading'
    },
    [AUTH_SUCCESS] (state, { token, access }) {
      state.status = 'success'
      state.token = token
      state.user_access = access
    },
    [AUTH_ERROR] (state) {
      state.status = 'error'
    },
    [LOGOUT] (state) {
      state.status = ''
      state.token = ''
    },
    [SET_USER] (state, user) {
      state.user = user.data
    },
    [SET_ORDERS] (state, orders) {
      state.orders = orders.data
    },
    [SET_CODES] (state, codes) {
      state.codes = codes
    },
    [SET_ADMINCODES] (state, admincodes) {
      state.admincodes = admincodes
    },
    [SET_STATEMENTS] (state, statements) {
      state.statements = statements
    },
    [SET_HUNTS] (state, hunts) {
      state.hunts = hunts
    },
    [SET_PROMOS] (state, promos) {
      state.promos = promos
    },
    [SET_ITEMS] (state, items) {
      state.items = items
    },
    [SET_NFE_ITEMS] (state, nfeItems) {
      state.nfe_items = nfeItems
    },
    [SET_USERS] (state, users) {
      state.users = users
    },
    [SET_COMPENSATIONS] (state, compensations) {
      state.compensations = compensations
    },
    [SET_ACTIVITIES] (state, activities) {
      state.activities = activities
    },
    [SET_ADMINACTIVITIES] (state, adminactivities) {
      state.adminactivities = adminactivities
    },
    [CLEAR_STATE] (state) {
      state.status = ''
      state.token = ''
      state.user = {}
      state.users = []
      state.orders = []
      state.codes = []
      state.admincodes = []
      state.statements = []
      state.activities = []
      state.adminactivities = []
      state.user_access = ''
      state.nfe_items = []
    },
    [SET_LOADING_STATE] (state, isLoading) {
      state.isLoading = isLoading
    },
    [SET_MODAL_SET_ITEM_RELATIONSHIP_OPENED] (state, modalSetItemRelationshipOpened) {
      state.modal_set_item_relationship_opened = modalSetItemRelationshipOpened
    },
    [SET_MODAL_UPLOAD_INVOICE_OPENED] (state, modalUploadInvoiceOpened) {
      state.modal_upload_invoice_opened = modalUploadInvoiceOpened
    },
    [SET_TOTAL_PAGES] (state, totalPages) {
      state.total_pages = totalPages
    },
    setPriceTableExpired (state, value) {
      state.isPriceTableExpired = value
    }
  },
  actions: {
    setLoadingState ({ commit }, isLoading) {
      commit('SET_LOADING_STATE', isLoading)
    },
    setModalSetItemRelationshipOpened ({ commit }, modalOpened) {
      commit('SET_MODAL_SET_ITEM_RELATIONSHIP_OPENED', modalOpened)
    },
    setModalUploadInvoiceOpened ({ commit }, modalOpened) {
      commit('SET_MODAL_UPLOAD_INVOICE_OPENED', modalOpened)
    },
    setNfeItems ({ commit }, nfeItems) {
      commit('SET_NFE_ITEMS', nfeItems)
    },
    updatePriceTableExpired ({ commit }, value) {
      commit('setPriceTableExpired', value)
    },
    async login ({ commit, dispatch }, user) {
      commit(SET_LOADING_STATE, true)
      commit(AUTH_REQUEST)
      try {
        const resp = await axios({ url: `${baseUrl}/auth/login`, data: user, method: 'POST' })
        const token = resp.data.data.attributes.token
        localStorage.setItem('token', token)
        localStorage.setItem('user_access', resp.data.data.access)
        axios.defaults.headers.common.Authorization = token
        commit(AUTH_SUCCESS, {
          token,
          access: resp.data.data.access
        })
        await dispatch('fetchUser', user)
        nextTick(() => {
          if (resp.data.data.access === 'admin') {
            router.push('/admin/dashboard')
          } else if (resp.data.data.access === 'logistics') {
            router.push('admin/compensation')
          } else {
            router.push('/dashboard')
          }
        })
      } catch (err) {
        commit(AUTH_ERROR)
        localStorage.removeItem('token')
        throw err
      } finally {
        commit(SET_LOADING_STATE, false)
      }
    },
    async logout ({ commit }) {
      commit(LOGOUT)
      localStorage.removeItem('token')
      localStorage.removeItem('user_access')
      delete axios.defaults.headers.common.Authorization
      commit(CLEAR_STATE)
      await nextTick(() => {
        router.push('/')
      })
    },
    async adminFetchUsers ({ commit }, params) {
      commit(SET_LOADING_STATE, true)
      try {
        const { page, pageSize, filterName, sort, direction } = params
        const response = await axios.get(`${baseUrl}/admin/users`,
          {
            params: {
              'filter[status]': 'active,inactive',
              'filter[name]': filterName,
              // include: 'store',
              'page[number]': page || 1,
              'page[size]': pageSize || 10,
              sort: sort ? `${direction === 'desc' ? '-' : ''}${sort}` : undefined
            }
          })
        commit(SET_USERS, response.data.data)
        commit(SET_TOTAL_PAGES, response.data.meta.last_page)
      } catch (error) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro inesperado.',
          type: 'error'
        })
        throw error
      } finally {
        commit(SET_LOADING_STATE, false)
      }
    },
    async adminFetchDistributor ({ commit }, params) {
      commit(SET_LOADING_STATE, true)
      try {
        const { page, pageSize, query, sort, direction } = params
        const response = await axios.get(`${baseUrl}/admin/users`,
          {
            params: {
              'filter[status]': 'active,inactive',
              'filter[role]': 'client',
              'filter[name]': query,
              // include: 'store',
              'page[number]': page || 1,
              'page[size]': pageSize || 10,
              sort: sort ? `${direction === 'desc' ? '-' : ''}${sort}` : undefined
            }
          })
        commit(SET_USERS, response.data.data)
        commit(SET_TOTAL_PAGES, response.data.meta.last_page)
      } catch (error) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro inesperado.',
          type: 'error'
        })
        throw error
      } finally {
        commit(SET_LOADING_STATE, false)
      }
    },
    async fetchActivities ({ commit, state }) {
      commit(SET_LOADING_STATE, true)
      try {
        const url = `${baseUrl}/my/activities`
        const response = await axios.get(url, {
          params: {
            'page[size]': 10,
            'page[number]': 1,
            sort: '-created'
          }
        })
        commit(SET_ACTIVITIES, response.data.data)
      } catch (error) {

      } finally {
        commit(SET_LOADING_STATE, false)
      }
    },
    async fetchAdminActivities ({ commit, state }) {
      commit(SET_LOADING_STATE, true)
      try {
        const url = `${baseUrl}/admin/activities`
        const response = await axios.get(url, {
          params: {
            'page[size]': 10,
            'page[number]': 1,
            include: ['user', 'userCount', 'userExists', 'responsible', 'responsibleCount', 'responsibleExists'].join(','),
            sort: '-created'
          }
        })
        commit(SET_ADMINACTIVITIES, response.data.data)
      } catch (error) {

      } finally {
        commit(SET_LOADING_STATE, false)
      }
    },
    async fetchUser ({ commit }) {
      commit(SET_LOADING_STATE, true)
      try {
        const response = await axios.get(`${baseUrl}/my/user`)
        response.data.data.attributes.discounts.current = Number(response.data.data.attributes.discounts.current)
        commit(SET_USER, response.data)
      } catch (error) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro inesperado.',
          type: 'error'
        })
        throw error
      } finally {
        commit(SET_LOADING_STATE, false)
      }
    },
    async fetchOrders ({ commit }, params) {
      if (params) {
        commit(SET_LOADING_STATE, true)
        const { page, query, sort, direction } = params
        try {
          let finalSort = '-id' // Definindo o padrão de classificação como '-id'
          if (sort) {
            // Se houver um parâmetro de classificação definido pelo usuário
            finalSort = `${direction === 'desc' ? '-' : ''}${sort}`
          }
          const response = await axios.get(`${baseUrl}/my/orders`, {
            params: {
              'filter[description]': query,
              include: ['store', 'items'].join(','),
              'page[number]': page,
              'page[size]': 10,
              sort: finalSort // Usando o valor final para sort
            }
          })
          commit(SET_ORDERS, response.data)
          commit(SET_TOTAL_PAGES, response.data.meta.last_page)
        } catch (err) {
          this.$notification.emit('flash', {
            message: 'Ocorreu um erro inesperado.',
            type: 'error'
          })
        } finally {
          commit(SET_LOADING_STATE, false)
        }
      } else {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro inesperado.',
          type: 'error'
        })
      }
    },
    async fetchStatements ({ commit }, params) {
      const { page, pageSize, query, sort = '-updated', direction } = params
      try {
        const response = await axios.get(`${baseUrl}/my/statements`, {
          params: {
            'filter[code]': query,
            'page[number]': page,
            // include: 'invoices',
            'page[size]': pageSize || 10,
            sort: sort ? `${direction === 'desc' ? '-' : ''}${sort}` : undefined
          }
        })

        commit(SET_STATEMENTS, response.data.data)
        commit(SET_TOTAL_PAGES, response.data.meta.last_page)
      } catch (err) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro inesperado.',
          type: 'error'
        })
      }
    },
    async adminFetchHunts ({ commit }, params) {
      commit(SET_LOADING_STATE, true)
      const { page, pageSize, sort, direction, filterName } = params
      try {
        const response = await axios.get(`${baseUrl}/admin/hunts`, {
          params: {
            // 'filter[code]': query,
            // include: ['user'],
            'page[number]': page || 1,
            'filter[name]': filterName,
            'page[size]': pageSize || 6,
            sort: sort ? `${direction === 'desc' ? '-' : ''}${sort}` : undefined
          }
        })
        commit(SET_HUNTS, response.data.data)
        commit(SET_TOTAL_PAGES, response.data.meta.last_page)
      } catch (err) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro inesperado.',
          type: 'error'
        })
      } finally {
        commit(SET_LOADING_STATE, false)
      }
      // }
    },
    async fetchItems ({ commit }, params) {
      commit(SET_LOADING_STATE, true)
      try {
        const { query } = params
        const response = await axios.get(`${baseUrl}/my/items`,
          {
            params: {
              'filter[item-name]': query,
              'page[size]': 15,
              'filter[active]': true
            }
          })
        const items = response.data.data
        items.forEach(item => {
          item.attributes.unit_discount_price = 0
        })
        const newItems = reactive(items)
        commit(SET_ITEMS, newItems)
      } catch (err) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro inesperado.',
          type: 'error'
        })
      } finally {
        commit(SET_LOADING_STATE, false)
      }
    },
    async adminFetchPromos ({ commit }, params) {
      commit(SET_LOADING_STATE, true)
      try {
        const { page, sort, direction } = params
        const response = await axios.get(`${baseUrl}/sales`,
          {
            params: {
              // 'filter[code]': query,
              include: 'store',
              'page[number]': page,
              'page[size]': 10,
              sort: sort ? `${direction === 'desc' ? '-' : ''}${sort}` : undefined
            }
          })
        commit(SET_PROMOS, response.data.data)
        commit(SET_TOTAL_PAGES, response.data.meta.last_page)
      } catch (err) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro inesperado.',
          type: 'error'
        })
      } finally {
        commit(SET_LOADING_STATE, false)
      }
    },
    async adminFetchCompensations ({ commit }, params) {
      commit(SET_LOADING_STATE, true)
      try {
        const { page, sort, direction } = params

        const defaultSort = 'id'
        const sortOrder = sort ? `${direction === 'asc' ? '-' : ''}${sort}` : `-${defaultSort}`
        const response = await axios.get(`${baseUrl}/admin/compensations`,
          {
            params: {
              // 'filter[code]': query,
              include: ['user', 'creator'].join(','),
              'page[number]': page,
              'page[size]': 10,
              sort: sortOrder
            }
          })
        commit(SET_COMPENSATIONS, response.data.data)
        commit(SET_TOTAL_PAGES, response.data.meta.last_page)
      } catch (error) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro inesperado.',
          type: 'error'
        })
      } finally {
        commit(SET_LOADING_STATE, false)
      }
    },
    async adminFetchProductsList ({ commit }, params) {
      commit(SET_LOADING_STATE, true)
      try {
        const { page, sort, direction } = params
        const response = await axios.get(`${baseUrl}/admin/users`, {
          params: {
            'page[number]': page,
            'filter[role]': 'client',
            'page[size]': 10,
            include: 'itemCode',
            sort: sort ? `${direction === 'desc' ? '-' : ''}${sort}` : undefined
          }
        })
        commit(SET_USERS, response.data.data)
        commit(SET_TOTAL_PAGES, response.data.meta.last_page)
      } catch (error) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro inesperado.',
          type: 'error'
        })
      } finally {
        commit(SET_LOADING_STATE, false)
      }
    },
    async fetchItemsCodes ({ commit }, params) {
      commit(SET_LOADING_STATE, true)
      try {
        const { userId, page, sort, direction, filterName } = params

        const response = await axios.get(`${baseUrl}/items/codes`, {
          params: {
            'filter[user]': userId,
            'page[number]': page,
            'filter[name]': filterName,
            'page[size]': 320,
            sort: sort ? `${direction === 'desc' ? '-' : ''}${sort}` : undefined
          }
        })
        commit(SET_CODES, response.data.data)
        commit(SET_TOTAL_PAGES, response.data.meta.last_page)
      } catch (error) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro inesperado.',
          type: 'error'
        })
      } finally {
        commit(SET_LOADING_STATE, false)
      }
    },
    async fetchAdminCodes ({ commit }, params) {
      commit(SET_LOADING_STATE, true)
      try {
        const { sectionId, page, sort, direction } = params

        const response = await axios.get(`${baseUrl}/items/codes`, {
          params: {
            'filter[user]': sectionId,
            'page[number]': page,
            'page[size]': 330,
            include: 'invoices',
            sort: sort ? `${direction === 'desc' ? '-' : ''}${sort}` : undefined
          }
        })
        commit(SET_ADMINCODES, response.data.data)
        commit(SET_TOTAL_PAGES, response.data.meta.last_page)
      } catch (error) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro inesperado.',
          type: 'error'
        })
      } finally {
        commit(SET_LOADING_STATE, false)
      }
    }
  },
  getters: {
    isLoading: state => state.isLoading,
    isLoggedIn: state => !!state.token,
    userIsAdmin: state => state.user_access === 'admin',
    authStatus: state => state.status,
    user: state => state.user,
    users: state => state.users,
    orders: state => state.orders,
    codes: state => state.codes,
    admincodes: state => state.admincodes,
    statements: state => state.statements,
    hunts: state => state.hunts,
    promos: state => state.promos,
    compensations: state => state.compensations,
    activities: state => state.activities,
    adminactivities: state => state.adminactivities,
    items: state => state.items,
    nfe_items: state => state.nfe_items,
    modal_set_item_relationship_opened: state => state.modal_set_item_relationship_opened,
    modal_upload_invoice_opened: state => state.modal_upload_invoice_opened,
    total_pages: state => state.total_pages,
    isPriceTableExpired: state => state.isPriceTableExpired
  }
})
