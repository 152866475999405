<template>
    <div v-if="isModalOpened" class="modal">
  <section class="popup-detail upload-nota" :class="{ opened: isModalOpened }">
    <span class="close small-btn close" @click="closeModal">&times;</span>
    <h1>Upload de nota</h1>
    <div class="send-files" :class="uploadSuccess ? 'success' : uploadSuccess === '' ? '' : 'failed'">
      <input type="file" @change="onFileChange" @dragover="onFileDragOver" @drop="onFileDrop">
      <div class="send-files-custom">
        <div class="icon icon-upload"></div>
        <p v-if="uploadSuccess === ''">Arraste e solte os arquivos aqui ou clique para selecionar</p>
        <p v-if="uploadSuccess === true">O upload do seu arquivo foi realizado com sucesso e já está em nosso sistema!</p>
        <p v-if="uploadSuccess === false">Algo deu errado com o upload do seu arquivo. Revise e tente novamente.</p>
      </div>
    </div>
    <div class="popup-table itens-table" v-if="nfeData">
      <table>
        <thead>
          <tr>
            <th>
              <p>Qtd</p>
            </th>
            <th>
              <p>Itens</p>
            </th>
            <th>
              <p>Desconto na nota</p>
            </th>
            <th>
              <p>Custo</p>
            </th>
            <th class="align-right">
              <p>Cashback</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="nfe_item in this.nfe_items" :key="nfe_item.code">
            <td class="qtd">
              <p>{{ nfe_item.quantity }}</p>
            </td>
            <td>
              <p class="product-bold">{{ nfe_item.relationships ? nfe_item.relationships.userItem.relationships.item.attributes.name : '' }}</p>
              <!-- <p>{{ nfe_item.relationships.userItem.relationships.item.attributes.name ? nfe_item.relationships.userItem.relationships.item.attributes.name : nfe_item.relationships.userItem.relationships.item.name}}</p> -->
              <p>{{ nfe_item.description }} COD {{nfe_item.code}}</p>
            </td>
            <td>
              {{nfe_item.hunt ? nfe_item.hunt.sellerPercent.toFixed(2) + "%" : 'Não se aplica'}}
            </td>
            <td>
              R$ {{ nfe_item.hunt ? $formatCentsToCurrency(nfe_item.hunt.unitCost) : $formatCentsToCurrency(nfe_item.subtotal)}}
            </td>
            <td class="align-right">
              R$ {{nfe_item.hunt ? $formatCentsToCurrency(nfe_item.hunt.cashback) : "0"}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="payment-detail" v-if="nfeData">
      <div class="total-wrap">
        <div class="total">
          <p>Total</p>
          <div>
            <h3>R$ {{ $formatCentsToCurrency(cashBackAmount) }}</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="button-wrap">
      <!--
      <div class="learn-calc">
        <div></div>
        <a class="main-link" href="">Entenda o cálculo</a>
      </div>
      -->
      <!-- {{ nfe_items.relationships.userItem.id }} -->
      <button class="main-btn" @click="submitNfe">Submeter nota</button>
    </div>
  </section>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '../../config/axiosConfig.js'

export default {
  data () {
    return {
      uploadedMedia: [],
      uploadSuccess: '',
      nfeData: false,
      isModalOpened: false,
      cashBackAmount: 0
    }
  },
  watch: {
    modal_upload_invoice_opened: {
      immediate: true,
      handler (newVal, oldVal) {
        this.isModalOpened = newVal
      }
    },
    modal_set_item_relationship_opened: {
      immediate: true,
      handler (newVal, oldVal) {
        if (oldVal && !newVal) {
          this.loadHuntData()
        }
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'items', 'nfe_items', 'modal_upload_invoice_opened', 'modal_set_item_relationship_opened']),
    itemsList () {
      return this.items.map(x => {
        return {
          id: x.relationships.item.id,
          name: x.relationships.item.attributes.name,
          ...x
        }
      })
    }
  },
  created () {
    this.fetchItems({ query: '' })
  },
  methods: {
    ...mapActions(['setLoadingState', 'setModalUploadInvoiceOpened', 'fetchItems', 'setNfeItems', 'setModalSetItemRelationshipOpened', 'fetchStatements']),
    closeModal () {
      this.nfeData = false
      this.uploadSuccess = ''
      this.setNfeItems([])
      this.fetchStatements({ page: 1 })
      this.setModalSetItemRelationshipOpened(false)
      this.setModalUploadInvoiceOpened(false)
      this.$emit('close-modal')
    },
    onFileDragOver (event) {
      event.preventDefault()
    },
    onFileDrop (event) {
      event.preventDefault()
      const files = event.dataTransfer.files
      if (files.length > 0) {
        this.uploadFile(files[0])
      }
    },
    onFileChange (e) {
      var files = e.target.files
      if (!files.length) { return }
      this.uploadFile(files[0])
    },
    async uploadFile (file) {
      this.setLoadingState(true)
      try {
        var formData = new FormData()
        formData.append('file', file)
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/my/invoices/media`, formData)
        this.uploadedMedia = response.data.data
        this.loadDataFromNfe(response.data.data.id)
      } catch (error) {
        let errorMessage = 'Houve um erro ao enviar sua NFe para o servidor'
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          /^A data de recebimento da NFe \(\d{2}\/\d{2}\/\d{4}\) não pode ter sido emitida a mais de 90 dias\.$/.test(error.response.data.message)) { errorMessage = 'Sua NF foi gerada há mais de 90 dias e não pode mais ser utilizada para ganho de cashback.' } else if (error.response && error.response.data && error.response.data.message) { errorMessage = error.response.data.message }
        this.$notification.emit('flash', { message: errorMessage, type: 'error' })
      } finally {
        this.setLoadingState(false)
      }
    },
    async loadHuntData () {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/my/invoices/media/${this.uploadedMedia.id}/load`)
      // -- this.setNfeItems(response.data.data.attributes.items.filter(nfeItem => nfeItem.hunt))
      this.setNfeItems(response.data.data.attributes.items.filter(item => item.relationships.userItem !== null))
      this.cashBackAmount = response.data.data.attributes.cashback
      // for (const item of response.data.data.attributes.items) {
      //   this.refundAmount += item.hunt.refund
      // }
    },
    async loadDataFromNfe (nfeId) {
      this.setLoadingState(true)
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/my/invoices/media/${nfeId}/check`)
        this.nfeData = response.data.data
        this.uploadSuccess = true

        this.setNfeItems(this.nfeData.attributes.items)

        this.setModalUploadInvoiceOpened(false)
        this.setModalSetItemRelationshipOpened(true)
        // this.closeModal()
      } catch (error) {
        this.$notification.emit('flash', { message: error.message, type: 'error' })
      } finally {
        this.setLoadingState(false)
      }
    },
    async submitNfe () {
      console.log('Itens para submissão:', this.nfe_items)
      try {
        this.setLoadingState(true)
        console.log(this.nfe_items)
        const newItems = this.nfe_items.map((item) => ({
          id: item.relationships.userItem.relationships.item.id,
          quantity: item.quantity,
          unit_price: item.unitPrice,
          refund: item.hunt !== null ? item.hunt.cashback : 0,
          discount: Math.round((item.hunt.sellerPercent * 100) / 100).toFixed(0),
          // hunt_discount: '',
          unit_cost: item.hunt ? item.hunt.unitCost : item.subtotal,
          subtotal: item.subtotal,
          description: item.description,
          code: item.code,
          user_item_id: item.relationships.userItem.id
        }))

        const formData = new FormData()
        formData.append('media_id', this.uploadedMedia.id)
        formData.append('user_id', this.user.id)
        formData.append('amount', this.nfeData.attributes.amount)
        formData.append('cashback', this.cashBackAmount)
        formData.append('code', this.nfeData.attributes.code)
        formData.append('number', this.nfeData.attributes.number)
        formData.append('series', this.nfeData.attributes.series)
        formData.append('cnae', this.nfeData.attributes.cnae)
        formData.append('uf', this.nfeData.attributes.uf)
        formData.append('municipality', this.nfeData.attributes.municipality)
        formData.append('issuance_at', this.nfeData.attributes.issuanceAt)

        newItems.forEach((item, index) => {
          Object.keys(item).forEach((key) => {
            formData.append(`items[${index}][${key}]`, item[key])
          })
        })

        await axios.post(`${process.env.VUE_APP_API_URL}/api/my/invoices`, formData)
        this.$notification.emit('flash', { message: 'Nota fiscal enviada com sucesso', type: 'success' })
        this.closeModal()
      } catch (error) {
        // console.log(error)
        console.log(error.response)
        var message = error.response ? error.response.data.message : 'Erro ao submeter a nota!'
        this.$notification.emit('flash', { message: message, type: 'error' })
      } finally {
        this.setLoadingState(false)
      }
    },
    async saveUserItemsRelationships () {
      const UserId = this.user.id
      for (const [key, value] of Object.entries(this.userItemsRelationships)) {
        try {
          const formData = {
            item_id: value,
            code: key,
            UserId: UserId
          }

          const url = `${process.env.VUE_APP_API_URL}/api/my/items/${UserId}/code`
          await axios.post(url, formData)
        } catch (e) {
          console.error(e)
        } finally {

        }
      }
    }
  }
}
</script>
