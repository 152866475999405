<template>
  <div class="footer">
    <div class="rights">
      <span>© 2025 Antares Acoplamentos. Todos os direitos reservados. Versão 2.3 de 03 de março de 2025 14:02.</span>
    </div>
    <div class="mode-btn-wrap">
      <p>Modo escuro</p>
      <button class="switch-btn mode-btn" @click="toggleDarkMode"><span></span></button>
    </div>
  </div>
</template>
<script>
export default {

  data () {
    return {
      darkMode: false
    }
  },
  methods: {
    toggleDarkMode () {
      this.darkMode = !this.darkMode
      if (this.darkMode) {
        document.body.classList.add('dark-mode')
      } else {
        document.body.classList.remove('dark-mode')
      }
    }
  }
}
</script>
